<template>
  <div class="paginator">
    <button :disabled="currentPage === 1" @click="goToPage(1)">First</button>
    <!--    <button @click="prevPage" :disabled="currentPage === 1">Previous</button>-->

    <span v-for="pageNumber in visiblePages" :key="pageNumber">
      <button
        :class="{ active: currentPage === pageNumber }"
        @click="goToPage(pageNumber)"
      >
        {{ pageNumber }}
      </button>
    </span>

    <!--    <button @click="nextPage" :disabled="currentPage === totalPages">-->
    <!--      Next-->
    <!--    </button>-->
    <button
      :disabled="currentPage === totalPages"
      @click="goToPage(totalPages)"
    >
      Last
    </button>
  </div>
</template>

<script>
export default {
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentPage: parseInt(this.page) || 1,
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    },
    visiblePages() {
      const totalVisiblePages = 5
      const startPage = Math.max(
        1,
        this.currentPage - Math.floor(totalVisiblePages / 2)
      )
      const endPage = Math.min(
        this.totalPages,
        startPage + totalVisiblePages - 1
      )

      const pages = []
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i)
      }
      return pages
    },
  },
  methods: {
    goToPage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber
        this.$emit('page-changed', this.currentPage)
      }
    },
    nextPage() {
      this.goToPage(this.currentPage + 1)
    },
    prevPage() {
      this.goToPage(this.currentPage - 1)
    },
  },
}
</script>

<style scoped>
.paginator {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

.active {
  color: white;
  @apply bg-newVisionBlue-950 rounded;
}
</style>
