import revive_payload_client_gSG0ZFYwED from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@8.57.1_rollup@4.24.0_sass@1.79.4_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KqbefmZkCP from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@8.57.1_rollup@4.24.0_sass@1.79.4_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_zzh6wtzGmr from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@8.57.1_rollup@4.24.0_sass@1.79.4_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MfU0D3dslB from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.24.0_vite@5.4.8_vue@3.5.11/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_77ao8yG6BN from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@8.57.1_rollup@4.24.0_sass@1.79.4_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_j8e5gFFlkI from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@8.57.1_rollup@4.24.0_sass@1.79.4_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_W5G5pVDW3s from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@8.57.1_rollup@4.24.0_sass@1.79.4_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_17E5DM9PNi from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@8.57.1_rollup@4.24.0_sass@1.79.4_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_7N4C2QRW2d from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.24.0_typescript@5.6.2_vue@3.5.11/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "A:/Solutions/NewVision/newvision.ge/.nuxt/components.plugin.mjs";
import prefetch_client_y8rvCDywNl from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@8.57.1_rollup@4.24.0_sass@1.79.4_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_r1k9hxYeVF from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/@sentry+nuxt@8.33.1_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_oecmthpck5lr2l5hw2uis52bii/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "A:/Solutions/NewVision/newvision.ge/.nuxt/sentry-client-config.mjs";
import titles_CLvFueuW2f from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.16_h3@1.13.0_rollup@4.24.0_vite@5.4.8_vue@3.5.11/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_2J4zmW8eIu from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.16_h3@1.13.0_rollup@4.24.0_vite@5.4.8_vue@3.5.11/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import siteConfig_kp2JnIlUbh from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_rollup@4.24.0_vite@5.4.8_vue@3.5.11/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_g4TeGJMdC1 from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_rollup@4.24.0_vite@5.4.8_vue@3.5.11/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_MmUDOVHRlf from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/nuxt-snackbar@1.1.2_rollup@4.24.0_vue@3.5.11/node_modules/nuxt-snackbar/dist/runtime/plugin.js";
import plugin_ky96t2SMdA from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/@cssninja+nuxt-toaster@0.3.12_rollup@4.24.0_vue@3.5.11/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import nuxt_plugin_WoYiHY2EFc from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_rollup@4.24.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_Tmy1L0dnIH from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/nuxt-lazy-load@3.0.4_rollup@4.24.0/node_modules/nuxt-lazy-load/dist/runtime/plugin.mjs";
import plugin_client_sfFM6tnXje from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/nuxt-gtag@1.2.1_rollup@4.24.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import switch_locale_path_ssr_uQxXoWhtBq from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_0T4Bi5RaJ6 from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_rz3gPkYs9p from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_rollup@4.24.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import gmap_J0VO3gUg5S from "A:/Solutions/NewVision/newvision.ge/plugins/gmap.js";
import vite_preload_error_handler_client_GhmGzihOc0 from "A:/Solutions/NewVision/newvision.ge/plugins/vite-preload-error-handler.client.ts";
import awesome_dxYinEZglg from "A:/Solutions/NewVision/newvision.ge/plugins/awesome.ts";
import clarity_lHPUJEmheh from "A:/Solutions/NewVision/newvision.ge/plugins/clarity.js";
import hotjar_HHyDP6XDX8 from "A:/Solutions/NewVision/newvision.ge/plugins/hotjar.js";
export default [
  revive_payload_client_gSG0ZFYwED,
  unhead_KqbefmZkCP,
  router_zzh6wtzGmr,
  _0_siteConfig_MfU0D3dslB,
  payload_client_77ao8yG6BN,
  navigation_repaint_client_j8e5gFFlkI,
  check_outdated_build_client_W5G5pVDW3s,
  chunk_reload_client_17E5DM9PNi,
  plugin_vue3_7N4C2QRW2d,
  components_plugin_KR1HBZs4kY,
  prefetch_client_y8rvCDywNl,
  sentry_client_r1k9hxYeVF,
  sentry_client_config_o34nk2sJbg,
  titles_CLvFueuW2f,
  defaults_2J4zmW8eIu,
  siteConfig_kp2JnIlUbh,
  inferSeoMetaPlugin_g4TeGJMdC1,
  plugin_MmUDOVHRlf,
  plugin_ky96t2SMdA,
  nuxt_plugin_WoYiHY2EFc,
  plugin_Tmy1L0dnIH,
  plugin_client_sfFM6tnXje,
  switch_locale_path_ssr_uQxXoWhtBq,
  i18n_0T4Bi5RaJ6,
  plugin_client_rz3gPkYs9p,
  gmap_J0VO3gUg5S,
  vite_preload_error_handler_client_GhmGzihOc0,
  awesome_dxYinEZglg,
  clarity_lHPUJEmheh,
  hotjar_HHyDP6XDX8
]