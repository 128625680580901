import { defineNuxtPlugin } from '#app/nuxt'
import { LazyLayoutPageContent, LazyLayoutPageFooter, LazyLayoutPageHeader, LazyLayoutPageLangSwitch, LazyLayoutPageMenu, LazyLayoutPageNavbar, LazyLayoutPageNavbarMenuItem, LazyLayoutPageNavbarMenuWrapper, LazyLayoutPageSearch, LazyLayoutPageSectionTitle, LazyLayoutPageSection, LazyLayoutPageTitle, LazyLayoutPageWrapper, LazyVisionBlogAccordion, LazyVisionBlogButtonsNext, LazyVisionBlogButtonsPrev, LazyVisionBlogCard, LazyVisionBlogCardStandard, LazyVisionBlogCategory, LazyVisionBlogEvent, LazyVisionBlogFiles, LazyVisionBlogShare, LazyVisionBlogTestimonial, LazyVisionBreadcrumbs, LazyVisionElectronicResources, LazyVisionEventDetails, LazyVisionFile, LazyVisionGeneralPromotionBrandsSvg, LazyVisionGeneralPromotionBrandsSwiper, LazyVisionGeneralPromotionBrandsSwiperSlide, LazyVisionGeneralPromotion, LazyVisionGeneralApplications, LazyVisionGeneralFormNoDialog, LazyVisionGeneralForm, LazyVisionGeneralImageGallery, LazyVisionGeneralInstagramCta, LazyVisionGeneralPaginator, LazyVisionGeneralPdfViewer, LazyVisionGeneralSdgTag, LazyVisionGeneralStaff, LazyVisionGeorgianLawReviewArticleEntry, LazyVisionGeorgianLawReviewJournalEntry, LazyVisionJournalHorizontalJournal, LazyVisionJournalHorizontalPublication, LazyVisionJournalMainDetails, LazyVisionJournalVerticalEntry, LazyVisionProjectFile, LazyVisionSchoolItem, LazyVisionSchoolList, LazyVisionSchoolProgram, LazyVisionSchoolTempVertical, LazyVisionSchoolVerticalListing, LazyPageHomeResearch, LazyPageHomeHorizontalBlog, LazyPageHomeHorizontalEvent, LazyPageHomeLifeVideo, LazyPageHomeLife, LazyPageHomeMainNews, LazyPageHomeStudy } from '#components'
const lazyGlobalComponents = [
  ["LayoutPageContent", LazyLayoutPageContent],
["LayoutPageFooter", LazyLayoutPageFooter],
["LayoutPageHeader", LazyLayoutPageHeader],
["LayoutPageLangSwitch", LazyLayoutPageLangSwitch],
["LayoutPageMenu", LazyLayoutPageMenu],
["LayoutPageNavbar", LazyLayoutPageNavbar],
["LayoutPageNavbarMenuItem", LazyLayoutPageNavbarMenuItem],
["LayoutPageNavbarMenuWrapper", LazyLayoutPageNavbarMenuWrapper],
["LayoutPageSearch", LazyLayoutPageSearch],
["LayoutPageSectionTitle", LazyLayoutPageSectionTitle],
["LayoutPageSection", LazyLayoutPageSection],
["LayoutPageTitle", LazyLayoutPageTitle],
["LayoutPageWrapper", LazyLayoutPageWrapper],
["VisionBlogAccordion", LazyVisionBlogAccordion],
["VisionBlogButtonsNext", LazyVisionBlogButtonsNext],
["VisionBlogButtonsPrev", LazyVisionBlogButtonsPrev],
["VisionBlogCard", LazyVisionBlogCard],
["VisionBlogCardStandard", LazyVisionBlogCardStandard],
["VisionBlogCategory", LazyVisionBlogCategory],
["VisionBlogEvent", LazyVisionBlogEvent],
["VisionBlogFiles", LazyVisionBlogFiles],
["VisionBlogShare", LazyVisionBlogShare],
["VisionBlogTestimonial", LazyVisionBlogTestimonial],
["VisionBreadcrumbs", LazyVisionBreadcrumbs],
["VisionElectronicResources", LazyVisionElectronicResources],
["VisionEventDetails", LazyVisionEventDetails],
["VisionFile", LazyVisionFile],
["VisionGeneralPromotionBrandsSvg", LazyVisionGeneralPromotionBrandsSvg],
["VisionGeneralPromotionBrandsSwiper", LazyVisionGeneralPromotionBrandsSwiper],
["VisionGeneralPromotionBrandsSwiperSlide", LazyVisionGeneralPromotionBrandsSwiperSlide],
["VisionGeneralPromotion", LazyVisionGeneralPromotion],
["VisionGeneralApplications", LazyVisionGeneralApplications],
["VisionGeneralFormNoDialog", LazyVisionGeneralFormNoDialog],
["VisionGeneralForm", LazyVisionGeneralForm],
["VisionGeneralImageGallery", LazyVisionGeneralImageGallery],
["VisionGeneralInstagramCta", LazyVisionGeneralInstagramCta],
["VisionGeneralPaginator", LazyVisionGeneralPaginator],
["VisionGeneralPdfViewer", LazyVisionGeneralPdfViewer],
["VisionGeneralSdgTag", LazyVisionGeneralSdgTag],
["VisionGeneralStaff", LazyVisionGeneralStaff],
["VisionGeorgianLawReviewArticleEntry", LazyVisionGeorgianLawReviewArticleEntry],
["VisionGeorgianLawReviewJournalEntry", LazyVisionGeorgianLawReviewJournalEntry],
["VisionJournalHorizontalJournal", LazyVisionJournalHorizontalJournal],
["VisionJournalHorizontalPublication", LazyVisionJournalHorizontalPublication],
["VisionJournalMainDetails", LazyVisionJournalMainDetails],
["VisionJournalVerticalEntry", LazyVisionJournalVerticalEntry],
["VisionProjectFile", LazyVisionProjectFile],
["VisionSchoolItem", LazyVisionSchoolItem],
["VisionSchoolList", LazyVisionSchoolList],
["VisionSchoolProgram", LazyVisionSchoolProgram],
["VisionSchoolTempVertical", LazyVisionSchoolTempVertical],
["VisionSchoolVerticalListing", LazyVisionSchoolVerticalListing],
["PageHomeResearch", LazyPageHomeResearch],
["PageHomeHorizontalBlog", LazyPageHomeHorizontalBlog],
["PageHomeHorizontalEvent", LazyPageHomeHorizontalEvent],
["PageHomeLifeVideo", LazyPageHomeLifeVideo],
["PageHomeLife", LazyPageHomeLife],
["PageHomeMainNews", LazyPageHomeMainNews],
["PageHomeStudy", LazyPageHomeStudy],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
