export default defineNuxtPlugin(() => {
  if (process.client) {
    window.addEventListener('vite:preloadError', (event: Event) => {
      // Prevent the default error from being thrown
      event.preventDefault()

      console.error('Vite preload error occurred:', (event as any).payload)

      // You can choose to refresh the page
      window.location.reload()

      // Or you can implement a more sophisticated retry mechanism
      // For example, you could show a modal to the user asking them to refresh
      // or attempt to re-fetch the assets programmatically
    })
  }
})
